import './App.css';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';

const theme = createTheme({
  palette: {
    primary: {
      main: "#127D7D",
    },
    secondary: {
      main: "#171C26",
    },
  }
});

function App() {
  return (
    <ThemeProvider theme={theme}>
      <div className="App" style={{ backgroundColor: "#062323", minHeight: "100vh", display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center" }}>
        <div style={{ maxWidth: "600px", textAlign: "center", padding: "0 20px" }}>
          <Typography variant="h2" style={{ fontFamily: "Inter", fontWeight: 600, color: "#FFFFFF", padding: "30px" }}>
            Coming Soon
          </Typography>
          <Typography variant="h1" style={{ fontFamily: "Inter", fontWeight: 600, color: "#FFFFFF", maxWidth: "100%", fontSize: "2rem" }}>
            Carbonsage.ai
          </Typography>
        </div>
      </div>
    </ThemeProvider>
  );
}

export default App;
